<div class="page page--secondary" [class.page--blured]="menu" *ngIf="userInfo">
    <div class="page__inner">
        <a [routerLink]="['/home/sell/existing-user']" class="page__back">
            <span class="visually-hidden">Back</span>
        </a>
        <h1 class="page__title">EXISTING USER</h1>
        <div class="page__head">
            <div class="page__left">
                <div class="page__tel" #userTel>{{getPhone(userInfo) | mask: getPhoneMask(userInfo)}}</div>
                <div class="page__pin">{{getPin(userInfo)}}</div>
            </div>
            <div class="page__right">
                <button (click)="menu = true" class="page__options">
                    <svg width="21" height="5" viewBox="0 0 21 5" xmlns="http://www.w3.org/2000/svg">
                        <use xlink:href="../../../../assets/images/sprite.svg#menu-dots"></use>
                    </svg>
                </button>
            </div>
        </div>
        <div class="page__form">
            <form [formGroup]="sellForm" (ngSubmit)="onSubmit()" class="form-simple form-simple--primary">
                <div class="form-simple__item form-simple__item--amount">
                    <input mask="separator.0"
                           thousandSeparator=","
                           formControlName="amount"
                           placeholder="Amount"
                           [type]="'tel'"
                           class="form-simple__input"
                           autocomplete="off">
                    <span>.00</span>
                </div>

                <div class="form-simple__btns">
                    <button class="button snake-btn is-notouched" [disabled]="sellForm.invalid">
                        SELL
                        <svg><rect></rect></svg>
                    </button>
                </div>
            </form>
        </div>
        <div class="page__body">

            <div class="dropdown js-debit-parent is-hidden">
                <div class="dropdown__head" (click)="toggleDropdown($event)">
                    <div class="dropdown__title">
                        ISSUED FUNDS
                    </div>
                    <div class="dropdown__total">
                        {{getIssuedFunds(userInfo) | currency}}
                    </div>
                    <svg class="dropdown__arrow" width="21" height="12">
                        <use xlink:href="../../../../assets/images/sprite.svg#arrow-bottom"></use>
                    </svg>
                </div>
                <div class="dropdown__body" (scroll)="scroll($event, debitData)">
                    <table class="transactions">
                        <thead>
                            <tr class="transactions__head">
                                <th class="transactions__type">TYPE</th>
                                <th class="transactions__date">DATE</th>
                                <th class="transactions__amount">AMOUNT</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let action of debitData.items" class="transactions__item js-debit-item">
                                <td class="transactions__type">
                                    <svg width="18px" height="14px">
                                        <use [attr.xlink:href]="'../../../../assets/images/sprite.svg#'+getPayment(action.type)"></use>
                                    </svg>
                                </td>
                                <td class="transactions__date">{{getDate(action.created) | date: 'MM-dd-yyyy'}}</td>
                                <td class="transactions__amount">{{action.amount | currency}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="dropdown js-credit-parent is-hidden">
                <div class="dropdown__head" (click)="toggleDropdown($event)">
                    <div class="dropdown__title">
                        REDEMPTIONS
                    </div>
                    <div class="dropdown__total">
                        {{getRedemtions(userInfo) | currency}}
                    </div>
                    <svg class="dropdown__arrow" width="21" height="12">
                        <use xlink:href="../../../../assets/images/sprite.svg#arrow-bottom"></use>
                    </svg>
                </div>
                <div class="dropdown__body" (scroll)="scroll($event, creditData)">
                    <table class="transactions">
                        <tr class="transactions__head">
                            <th class="transactions__type">TYPE</th>
                            <th class="transactions__date">DATE</th>
                            <th class="transactions__amount">AMOUNT</th>
                        </tr>
                        <tr *ngFor="let action of creditData.items" class="transactions__item js-credit-item">
                            <td class="transactions__type">
                                <svg width="18px" height="14px">
                                    <use [attr.xlink:href]="'../../../../assets/images/sprite.svg#'+getPayment(action.type)"></use>
                                </svg>
                            </td>
                            <td class="transactions__date">{{getDate(action.created) | date: 'MM-dd-yyyy'}}</td>
                            <td class="transactions__amount">{{action.amount | currency}}</td>
                        </tr>
                    </table>
                </div>
            </div>

        </div>
    </div>
</div>
<div class="addition-menu" [class.is-active]="menu" *ngIf="userInfo">
    <ul class="addition-menu__list">
        <li class="addition-menu__item">
            <button (click)="askFreezeUser()">Freeze</button>
        </li>
        <li class="addition-menu__item">
            <button (click)="askDeleteUser()">Delete</button>
        </li>
    </ul>
    <button (click)="menu = false" class="addition-menu__btn">Cancel</button>
</div>
