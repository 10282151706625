import {Component, OnInit} from '@angular/core';
import {routerTransition, slideToLeft} from "../../animations/router.animations";
import {ReportsService} from "../../service/reports.service.ts/reports.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ExampleHeader} from "../../shared/datapicker-header/datapicker-header";
import {PopupComponent} from "../../shared/popup/popup.component";
import {LoaderService} from "../../service/loader.service";
import {AuthenticationService} from "../../modules/auth/_services/authentication.service";
import {PhoneMaskService} from "../../service/phoneMask.service";

@Component({
    selector: 'app-reports',
    templateUrl: './reports.component.html',
    styleUrls: ['./reports.component.scss'],
    animations: [slideToLeft],
    host: {'[@slideToLeft]': ''}
})
export class ReportsComponent implements OnInit {
    reportsForm: FormGroup;
    exampleHeader = ExampleHeader;
    phoneMask = this.phoneMaskService.setPhoneMask();

    // PAGINATION NEW
    usersData = {
        name: 'users',
        prevPagination: 0,
        previewPagination: 1,
        lastScrollTop: 0,
        dataRichEnd: false,
        items: [],
        limit: 0,
        loading: false,
        countLastAddedItems: 0,
        canScroll: true,
        selector: '.js-info-row',
        selectorParent: '.page__body',
        countTotalItems: 0,
        lastAddedItem: 0
    }

    constructor(private reportsService: ReportsService,
                private popupComponent: PopupComponent,
                private loaderService: LoaderService,
                private phoneMaskService: PhoneMaskService,
                private auth:AuthenticationService,
                private formBuilder: FormBuilder,) {
        this.reportsForm = this.formBuilder.group({
            phone: [null],
            from: [null],
            to: [null],
        });
    }

    data;
    page;

    ngOnInit() {
        this.getData();
    }

    getData() {
        const page = this.page ? this.page : 1;
        const phone = this.reportsForm.controls.phone.value ? this.reportsForm.controls.phone.value : '';
        const from = this.reportsForm.controls.from.value ? this.reportsForm.controls.from.value : '';
        const to = this.reportsForm.controls.to.value ? this.reportsForm.controls.to.value : '';

        this.loaderService.show();

        this.reportsService.getData(page, phone, from, to).subscribe(data => {
            this.loaderService.hide();
            try {
                if(data.status){
                    this.resetPagination(this.usersData);
                    this.data = data;

                    this.usersData.items = data.result;

                    this.usersData.limit = data.limit ? data.limit : 40;
                    this.usersData.countTotalItems = data.totalUsers;
                    this.usersData.lastAddedItem = this.usersData.items.length;
                    this.usersData.dataRichEnd = +this.usersData.countTotalItems === +this.usersData.lastAddedItem;
                    this.usersData.canScroll = this.usersData.items.length >= this.usersData.limit && !this.usersData.dataRichEnd;

                }
            } catch (e) {
                this.popupComponent.showPopupError({text: 'Sorry there was an error try again later.'});
            }

        }, (error) => {
            this.loaderService.hide();
        });
    }

    onSubmit() {

        this.getData();
    }

    isFormValid() {
        const phone = this.reportsForm.controls.phone.value;
        const from = this.reportsForm.controls.from.value;
        const to = this.reportsForm.controls.to.value;
        if (phone || from || to) {
            return true;
        }
        return false;
    }


    ///////---SCROLLING PAGINATION FUNCTIONAL---///////

    resetPagination(data) {
        data.prevPagination = 0;
        data.previewPagination = 1;
        data.lastScrollTop = 0;
        data.items= [];
        data.loading = false;
        data.countLastAddedItems = 0;
        data.lastAddedItem = 0;
    }

    // DETECTING SCROLL
    scroll(e, settings) {
        if (!settings.loading && settings.canScroll) {
            const elems = document.querySelectorAll(settings.selector);

            const rect = elems[elems.length - 1] !== undefined ? elems[elems.length - 1].getBoundingClientRect() : undefined;
            let rectStart;
            let documentRect = document.querySelector(settings.selectorParent).getBoundingClientRect();

            if (settings.prevPagination > 0) {
                rectStart = elems[0] !== undefined ? elems[Math.round(settings.limit*.8)].getBoundingClientRect() : undefined;
            } else {
                rectStart = undefined;
            }

            var st = e.target.pageYOffset || e.target.scrollTop;
            if (st > settings.lastScrollTop){
                if (rect !== undefined && rect.bottom < documentRect.bottom + rect.height * Math.round(settings.limit*.2) && !settings.dataRichEnd) {
                    settings.loading = true;
                    const loadingPage = settings.previewPagination + 1;
                    this.loadNewData(loadingPage, 'end', settings);

                }

            } else {
                if (e.target.scrollTop <= rect.height * 35  && settings.prevPagination > 0) {
                    settings.loading = true;
                    const loadingPage = (settings.countLastAddedItems < settings.limit &&  settings.countLastAddedItems !== 0) ? settings.prevPagination - 1 : settings.prevPagination;
                    this.loadNewData(loadingPage, 'start', settings);
                }
            }
            settings.lastScrollTop = st <= 0 ? 0 : st;

        }
    }

    // REQUESTS ON ADDING NEW DATA
    loadNewData(page, step = 'end', settings):any {
        const phone = this.reportsForm.controls.phone.value ? this.reportsForm.controls.phone.value : '';
        const from = this.reportsForm.controls.from.value ? this.reportsForm.controls.from.value : '';
        const to = this.reportsForm.controls.to.value ? this.reportsForm.controls.to.value : '';

        this.reportsService.getData(page, phone, from, to).subscribe(data => {
            this.dataAddingLogic(page, step, data, settings);
        });
    }

    // ADDING NEW DATA
    dataAddingLogic(page, step, data, settings) {
        //console.log(page, step, data, settings)
        if (data.status) {

            // ADD PAGE TO THE END
            if (step === 'end') {

                // UPDATE SETTINGS
                settings.lastAddedItem += data.result.length;
                settings.previewPagination += 1;
                if (+settings.lastAddedItem === +settings.countTotalItems) {
                    settings.dataRichEnd = true;
                }

                // DATA RICH END LOGIC
                if (settings.dataRichEnd) {
                    settings.countLastAddedItems = data.result.length;
                    if (settings.items.length > (settings.limit * 2)) {
                        settings.prevPagination += 1;
                    }

                } else {
                    // REMOVE PAGE FROM BEGINING
                    if (settings.items.length > (settings.limit * 2)) {
                        settings.items.splice(0, data.result.length);
                        settings.prevPagination += 1;
                    }
                }

                // ADD PAGE TO THE END
                for (let i = 0; i < data.result.length; i++) {
                    settings.items.push(data.result[i]);
                };
            }

            // ADD PAGE TO BEGIN
            if ((step === 'start') && settings.prevPagination) {

                let additionalRemovedItems = 0;

                if (settings.countLastAddedItems < settings.limit && settings.countLastAddedItems !== 0) {
                    additionalRemovedItems = settings.countLastAddedItems;
                    settings.prevPagination -= 2;
                    settings.previewPagination -= 2;
                }
                else {
                    settings.prevPagination -= 1;
                    settings.previewPagination -= 1;
                }

                // REMOVE PAGE FROM END
                settings.items.splice(settings.items.length - data.result.length - additionalRemovedItems, data.result.length + additionalRemovedItems);
                settings.lastAddedItem -= data.result.length + additionalRemovedItems;

                // ADD PAGE TO BEGINING
                for (let i = data.result.length-1; i >= 0; i--) {
                    settings.items.unshift(data.result[i]);
                }
                settings.dataRichEnd = false;

            }

            settings.loading = false;
        }
    }

    getPhoneMask(item){
        if(item.countryId){
            return this.phoneMaskService.getPhoneMaskByCountryId(item.countryId);
        }else{
            return this.phoneMaskService.getDefaultPhoneMask();
        }

    }
}
