<div class="sell">
    <div class="sell__inner">
        <div class="navigation">
            <a [routerLink]="['/home/sell/new-user']" class="navigation__button button snake-btn is-notouched">
                NEW USER
                <svg><rect></rect></svg>
            </a>
            <a [routerLink]="['/home/sell/existing-user']" class="navigation__button navigation__button--secondary button snake-btn is-notouched">
                EXISTING USER
                <svg><rect></rect></svg>
            </a>
        </div>
    </div>
</div>

