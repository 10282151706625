import {Inject, Injectable, NgZone} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {ActivatedRoute, Router} from "@angular/router";

@Injectable({
    providedIn: 'root'
})
export class LoaderService {
    public visible = new BehaviorSubject(false);

    constructor(private ngZone: NgZone,
                private route: ActivatedRoute,
                private router: Router) {

    }

    show() {
        this.visible.next(true);
        document.body.classList.add('is-hidden');

    }

    hide() {
        this.visible.next(false);
        document.body.classList.remove('is-hidden');
    }

}
