import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {HomeComponent} from './home/home.component';
import {MainComponent} from './main/main.component';
import {LoginComponent} from "./login/login.component";
import {SignUpComponent} from "./signup/signup.component";

import {AuthGuard} from '../modules/auth/_guards/auth.guard';
import {SellComponent} from "./sell/sell.component";
import {ReportsComponent} from "./reports/reports.component";
import {NewUserComponent} from "./sell/new-user/new-user.component";
import {ExistingUserComponent} from "./sell/existing-user/existing-user.component";
import {ExistingUserInfoComponent} from "./sell/existing-user-info/existing-user-info.component";

const routes: Routes = [
    {
        path: '', component: MainComponent, children: [
            {path: '', component: LoginComponent},
            {path: 'login', component: LoginComponent},
            {path: 'signup', component: SignUpComponent},
            // {path: 'home', component: HomeComponent, canActivate: [AuthGuard], children: [
            {path: 'home', component: HomeComponent, children: [
                    {path: 'sell', component: SellComponent, children: [

                        ]},
                    {path: 'reports', component: ReportsComponent},
                    {path: 'sell/new-user', component: NewUserComponent},
                    {path: 'sell/existing-user', component: ExistingUserComponent},
                    {path: 'sell/existing-user/existing-user-info/:id', component: ExistingUserInfoComponent},
                ]},
            {path: '**', redirectTo: 'login', pathMatch: 'full'}
        ]
    }

];



@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class PagesModule {
}
