import {Component, HostListener, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {MenuService} from "./service/menu.service";
import {LoginService} from "./service/login.service";
import {NavigationEnd, NavigationStart, Router} from "@angular/router";

import {PhoneService} from "./service/phone.service";
import {StripeService} from "./service/stripe/stripe.service";
import {MenuBottomAddContentService} from "./service/menu/menuBottomAddContent.service";
import {InstallService} from "./service/install.service";
import {AuthenticationService} from "./modules/auth/_services/authentication.service";
import {ConnectionService} from "ng-connection-service";
import {PopupComponent} from "./shared/popup/popup.component";

//declare var Stripe: Stripe;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    menu;
    signup = false;
    login = false;
    search = true;

    canListen = false;
    androidInstallBtn = false;
    IOSInstallBtn = false;
    deferredPrompt: any;
    isConnected = true;
    noInternetConnection = false;

    @HostListener('click', ['$event'])
    clickInside(event) {
        if  (this.canListen) {
            const snakeBtns = document.querySelectorAll('.snake-btn');
            snakeBtns.forEach(el => {
                el.classList.remove('is-notouched');
            });
        };

    }

    constructor(private menuService: MenuService,
                private router: Router,
                private phoneService:PhoneService,
                private stripeService:StripeService,
                private installService: InstallService,
                private connectionService: ConnectionService,
                private authenticationService: AuthenticationService,
                private popupComponent: PopupComponent,
                private menuBottomAddContentService: MenuBottomAddContentService,
                private loginService: LoginService) {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                //console.log('Navigation Start')
                this.canListen = false;
            }

            if (event instanceof NavigationEnd) {
                //console.log('Navigation End')
                const snakeBtns = document.querySelectorAll('.snake-btn');
                snakeBtns.forEach(el => {
                    (<HTMLElement>el).classList.add('is-notouched');
                })
                setTimeout(()  => {
                    this.canListen = true;
                }, 0)

            }
        });
        this.connectionService.monitor().subscribe(isConnected => {
            this.isConnected = isConnected;
            if (this.isConnected) {
                this.noInternetConnection = false;
                this.popupComponent.closePopup();
            } else {
                this.noInternetConnection = true;
                this.popupComponent.showPopupError({text: 'No Internet Connection: please, try to connect to the Internet', closeBtn: false})
            }
        });
    }

    ngOnInit() {
        this.phoneService.init();

        this.loginService.loginChanged.subscribe((value: any) => this.loginChanged(value));

        setTimeout(() => {
            this.menuService.menuChanged.subscribe((value: any) => this.menuChanged(value));
        });
        // this.stripeService.getPublishableKey().subscribe(data => {
        //     if (data.key) {
        //         this.addStripeJs(data.key);
        //     }
        // });

        this.detectBrowser();

        //this.detectIOSDevices();
        // this.hidePWAInstallBtns();

        // const fun = navigator['getInstalledRelatedApps'];
        // let listOfInstalledApps = fun.call(navigator);
        // listOfInstalledApps.forEach((app) => {
        //     //if your PWA exists in the array it is installed
        //     alert(app.platform);
        // });
        // const relatedApps = (navigator as any).getInstalledRelatedApps();
        // relatedApps.forEach((app) => {
        //     console.log(app.id, app.platform, app.url);
        // });
        // navigator.getInstalledRelatedApps()
        //     .then((relatedApps) => {
        //         relatedApps.forEach((app) => {
        //             console.log(app.id, app.platform, app.url);
        //         });
        //     });

        this.refreshToken();

        document.addEventListener('visibilitychange', () => {
            this.authenticationService.refreshTokenNow();
        });

    }

    ngOnDestroy() {

    }

    refreshToken(){
        let $this = this;
        setTimeout(function(){
            $this.authenticationService.refreshToken();
        },60*5*1000);
    }

    // Detects if device is in standalone mode
    isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator['standalone']);

    addStripeJs(key){
        this.stripeService.setPublishableKey(key);



    }

    menuChanged(status){
        this.menu = status;
    }

    signUpChanged(status){
        this.signup = status;
    }

    loginChanged(status){
        this.login = status;
    }

    searchVisibilityChanged(value) {
        this.search = value;
    }

    addToHomeScreen() {
        // wait for click install button by user

        this.deferredPrompt.prompt();
        this.deferredPrompt.userChoice.then(choiceResult => {
            if (choiceResult.outcome === 'accepted') {
                // user accept the prompt

                // lets hidden button
                this.androidInstallBtn = false;
                //overlay_button.classList.add('is-app');

            } else {
                console.log('User dismissed the prompt');
            }
            this.deferredPrompt = null;
        });
    }

    // hidePWAInstallBtns() {
    //     // Hide install btn if app is on Home Sreen
    //     if (this.isInStandaloneMode()) {
    //         this.androidInstallBtn = false;
    //         this.IOSInstallBtn = false;
    //
    //         alert('klaqksd')
    //         // ios_install_popup.style.display = 'none';
    //         // overlay_button.classList.add('is-app');
    //         // install_button.style.display = 'none';
    //     }
    // }

    // detectIOSDevices() {
    //     // Detects if device is on iOS
    //     const userAgent = window.navigator.userAgent.toLowerCase();
    //     const isIos = () => {
    //         return /iphone|ipad|ipod/.test( userAgent );
    //     };
    //
    //     // Checks if should display install popup notification:
    //     if (isIos() && !this.isInStandaloneMode()) {
    //         this.IOSInstallBtn = true;
    //         let device = 'device';
    //         console.log(userAgent);
    //         if  (/iphone/.test( userAgent )) {
    //             device = 'IPhone';
    //         } else if (/ipad/.test( userAgent )) {
    //             device = 'IPad';
    //         }
    //         this.ios_popup_text = `Install this webapp on your ${device}: `;
    //     }
    // }

    detectBrowser() {
        if (window.matchMedia('(display-mode: standalone)').matches) {
            //console.log('display-mode is standalone');
            //alert('display-mode is standalone');
            this.installService.appInstalled.next(true);
            this.installService.hide();
            this.IOSInstallBtn = false;
        } else {
            this.installService.show();
        }

    }
}


