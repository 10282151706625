import {Component, HostListener, OnInit} from '@angular/core';
import {InstallService} from "../../service/install.service";
import { environment } from '../../../environments/environment';

@Component({
    selector: 'app-install',
    templateUrl: './install.component.html',
    styleUrls: ['./install.component.scss']
})
export class InstallComponent implements OnInit {

    appInstalled = false;
    isBrowser;
    IOSInstallBtn = false;
    ios_popup_text;
    androidInstallBtn = false;
    deferredPrompt: any;

    @HostListener('window:beforeinstallprompt', ['$event'])
    onbeforeinstallprompt(e) {
        //console.log(e);
        // Prevent Chrome 67 and earlier from automatically showing the prompt
        // prevent default event
        e.preventDefault();

        // store install avaliable event
        this.deferredPrompt = e;

        // show button with display:block;
        this.androidInstallBtn = true;
        //this.appInstalled = true;
        //this.appInstalled = false;

        //console.log('4');
    }

    constructor(private installService: InstallService) {
        if(environment.production) {
            this.installService.visible.subscribe((value: any) => this.installChanged(value));
        }
        // this.installService.appInstalled.subscribe((value: any) => this.appInstalledChanged(value));
    }


    ngOnInit() {
        if (this.isIos()) {
            this.appInstalled = false;
        }else{
            //this.androidInstallBtn = true;
        }

        window.addEventListener('appinstalled', (evt) => {
            this.appInstalled = true;
        });

        let $this = this;
        setTimeout(function(){
            if (!$this.isIos() && !$this.androidInstallBtn) {
                $this.appInstalled = true;
            }
        },3000);

    }

    installChanged(value) {
        this.isBrowser = value;
    }

    appInstalledChanged(value) {
        this.appInstalled = value;
    }

    install() {

        if (this.isIos() && !this.isInStandaloneMode()) {
            let userAgent = window.navigator.userAgent.toLowerCase();
            this.IOSInstallBtn = true;
            let device = 'device';

            if  (/iphone/.test( userAgent )) {
                device = 'IPhone';
            } else if (/ipad/.test( userAgent )) {
                device = 'IPad';
            }
            this.ios_popup_text = `Install this webapp on your ${device}: `;
        } else {
            this.deferredPrompt.prompt();
            this.deferredPrompt.userChoice.then(choiceResult => {
                if (choiceResult.outcome === 'accepted') {
                    // user accept the prompt
                    // lets hidden button
                    //this.androidInstallBtn = false;
                    //this.appInstalled = true;
                    //document.location.reload();

                    this.appInstalled = true;

                } else {
                    console.log('User dismissed the prompt');
                }
                this.deferredPrompt = null;
            });
        }
    }

    // Detects if device is in standalone mode
    isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator['standalone']);
    isIos(){
        let userAgent = window.navigator.userAgent.toLowerCase();
        return /iphone|ipad|ipod/.test( userAgent );
    }

}
