import {Inject, Injectable, NgZone} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {REPORTS_CONFIG} from "../../config/api";
import {HttpClient} from "@angular/common/http";

@Injectable({
    providedIn: 'root'
})
export class ReportsService {
    private config = REPORTS_CONFIG;

    constructor(private http: HttpClient) {

    }

    getData(page, phone, from, to) {
        return this.http.post<any>(this.config.data, {page, phone, from, to});
    }
}
