<div class="page page--primary">
    <div class="page__inner">
        <a [routerLink]="['/home/sell']" class="page__back">
            <span class="visually-hidden">Back</span>
        </a>
        <h1 class="page__title">New User</h1>
        <div class="page__form">
            <form [formGroup]="newUserForm" (ngSubmit)="onSubmit()" class="form-simple form-simple--primary">
                <div class="form-simple__item">
                    <input [mask]=phoneMask
                           [dropSpecialCharacters]="false"
                           formControlName="phone"
                           placeholder="Phone Number"
                           [type]="'text'"
                           class="form-simple__input"
                           autocomplete="off">
                </div>
                <div class="form-simple__item form-simple__item--amount">
                    <input mask="separator.0"
                           thousandSeparator=","
                           formControlName="amount"
                           placeholder="Amount"
                           [type]="'tel'"
                           class="form-simple__input"
                           autocomplete="off">
                    <span>.00</span>
                </div>

                <div class="form-simple__btns">
                    <button class="button snake-btn is-notouched" [disabled]="newUserForm.invalid">
                        Sell
                        <svg><rect></rect></svg>
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>

