import {DOMAIN_API} from "./local_settings";
import {DOMAIN_DEMO_NAME_API,DOMAIN_DEMO_API} from "./demo_settings";
import {AuthConfig} from '../modules/auth/_services/auth-config';
import {Stripe} from "../models/stripe";
import {NewUser} from "../models/new-user";
import {ExistingUser} from "../models/existing-user";
import {Reports} from "../models/reports";

let API = DOMAIN_API + '/pos';
if(window.location.hostname == DOMAIN_DEMO_NAME_API){
    API = DOMAIN_DEMO_API + '/pos';
}

const AUTH = API + '/users/session';
const USERS = API + '/users';

export const AUTH_CONFIG: AuthConfig = {
    checkUser: USERS + '/register/check',
    register: USERS + '/register',
    login: AUTH + '/login',
    refresh: AUTH + '/refresh',
    current: AUTH + '/details',
    sendPin: USERS + '/pin/request',
    refreshToken: AUTH + '/refresh',
    checkPhoneAndSendPin: USERS + '/phone/check',
};

export const NEW_USER_CONFIG: NewUser = {
    save: API + '/users/user/createCustomer',
};

export const EXISTING_USER_CONFIG: ExistingUser = {
    getUsers: API + '/users/users',
    userInfo: API + '/users/user/paymentHistory',
    userCredit: API + '/users/user/paymentCredit',
    userDebit: API + '/users/user/paymentDebit',
    sell: API + '/users/user/deposit',
    freeze: API + '/users/user/freeze',
    delete: API + '/users/user/delete',
};

export const REPORTS_CONFIG: Reports = {
    data: API + '/reports/reports'
};

export const STRIPE_CONFIG: Stripe = {
    getPublishableKey: API+'/app/stripe/publishable-key',
};
