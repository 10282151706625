import {Component, OnInit} from '@angular/core';
import {AuthenticationService} from "../../modules/auth/_services/authentication.service";
import {Router} from "@angular/router";

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

    menu = false;


    constructor(
        private auth:AuthenticationService,
        private router: Router,
    ) {


    }


    ngOnInit() {

    }

    logout(){
        this.auth.logout();

        this.router.navigate(['/']);
    }

    showMenu(status) {
        this.menu = !this.menu;
    }

}
