import { REPORTS_CONFIG } from "../../config/api";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ReportsService = /** @class */ (function () {
    function ReportsService(http) {
        this.http = http;
        this.config = REPORTS_CONFIG;
    }
    ReportsService.prototype.getData = function (page, phone, from, to) {
        return this.http.post(this.config.data, { page: page, phone: phone, from: from, to: to });
    };
    ReportsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ReportsService_Factory() { return new ReportsService(i0.ɵɵinject(i1.HttpClient)); }, token: ReportsService, providedIn: "root" });
    return ReportsService;
}());
export { ReportsService };
