import {Component, HostListener, OnInit} from '@angular/core';
import {routerTransition, slideToRight} from "../../animations/router.animations";

@Component({
    selector: 'app-sell',
    templateUrl: './sell.component.html',
    styleUrls: ['./sell.component.scss'],
    animations: [slideToRight],
    host: {'[@slideToRight]': ''}
})
export class SellComponent implements OnInit {

    constructor() {

    }


    ngOnInit() {

    }

}
