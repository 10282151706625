
    <div class="calendar-header">
      <button mat-icon-button (click)="previousClicked('month')">
        <mat-icon>keyboard_arrow_left</mat-icon>
      </button>
      <span class="calendar-header-label">{{ selectedMonth }}</span>
      <button mat-icon-button (click)="nextClicked('month')">
        <mat-icon>keyboard_arrow_right</mat-icon>
      </button>
        <span class="calendar-header-label">{{ selectedYear }}</span>
        <span class="calendar-header-year-controls">
            <button
              mat-icon-button
              class="calendar-double-arrow"
              (click)="nextClicked('year')"
                  >
            <mat-icon>keyboard_arrow_up</mat-icon>
          </button>
          <button
                  mat-icon-button
                  class="calendar-double-arrow"
                  (click)="previousClicked('year')">
            <mat-icon>keyboard_arrow_down</mat-icon>
          </button>
        </span>

    </div>
  