import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Inject,
    OnDestroy
} from "@angular/core";
import {MatCalendar, MatDatepickerInputEvent} from "@angular/material/datepicker";
import {
    DateAdapter,
    MAT_DATE_FORMATS,
    MatDateFormats
} from "@angular/material/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import {APP_DATE_FORMATS} from "../shared.module";
// import {APP_DATE_FORMATS} from "../../pages/pages.module";

/** @title Datepicker with custom calendar header */
@Component({
    selector: "datepicker-header",
    templateUrl: './datapicker-header.html',
    styleUrls: ['./datapicker-header.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DatepickerCustomHeaderExample {
    exampleHeader = ExampleHeader;
}

/** Custom header component for datepicker. */
@Component({
    selector: "example-header",
    styles: [
        `
      .calendar-header {
        display: flex;
        align-items: center;
        padding: 0.5em;
          background-color: #000;
          color: #fff;
      }

      .calendar-header-label {
        flex: 1;
        height: 1em;
        font-weight: 500;
        text-align: center;
      }

      .calendar-double-arrow .mat-icon {
        margin: -22%;
      }
      .calendar-header-year-controls {
          display: flex;
          flex-flow: column;
      }
    `
    ],
    template: `
    <div class="calendar-header">
      <button mat-icon-button (click)="previousClicked('month')">
        <mat-icon>keyboard_arrow_left</mat-icon>
      </button>
      <span class="calendar-header-label">{{ selectedMonth }}</span>
      <button mat-icon-button (click)="nextClicked('month')">
        <mat-icon>keyboard_arrow_right</mat-icon>
      </button>
        <span class="calendar-header-label">{{ selectedYear }}</span>
        <span class="calendar-header-year-controls">
            <button
              mat-icon-button
              class="calendar-double-arrow"
              (click)="nextClicked('year')"
                  >
            <mat-icon>keyboard_arrow_up</mat-icon>
          </button>
          <button
                  mat-icon-button
                  class="calendar-double-arrow"
                  (click)="previousClicked('year')">
            <mat-icon>keyboard_arrow_down</mat-icon>
          </button>
        </span>

    </div>
  `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExampleHeader<D> implements OnDestroy {
    private _destroyed = new Subject<void>();
    events: string[] = [];
    _dateFormats = APP_DATE_FORMATS;
    constructor(
        private _calendar: MatCalendar<D>,
        private _dateAdapter: DateAdapter<D>,
        cdr: ChangeDetectorRef
    ) {
        _calendar.stateChanges
            .pipe(takeUntil(this._destroyed))
            .subscribe(() => cdr.markForCheck());
    }

    ngOnDestroy() {
        this._destroyed.next();
        this._destroyed.complete();
    }

    get selectedMonth() {
        return this._dateAdapter
            .format(
                this._calendar.activeDate,
                this._dateFormats.display.monthLabel
            )
            .toLocaleUpperCase();
    }

    get selectedYear() {
        return this._dateAdapter
            .format(
                this._calendar.activeDate,
                this._dateFormats.display.yearLabel
            )
            .toLocaleUpperCase();
    }

    previousClicked(mode: "month" | "year") {
        this._calendar.activeDate =
            mode === "month"
                ? this._dateAdapter.addCalendarMonths(this._calendar.activeDate, -1)
                : this._dateAdapter.addCalendarYears(this._calendar.activeDate, -1);
    }

    nextClicked(mode: "month" | "year") {
        this._calendar.activeDate =
            mode === "month"
                ? this._dateAdapter.addCalendarMonths(this._calendar.activeDate, 1)
                : this._dateAdapter.addCalendarYears(this._calendar.activeDate, 1);
    }



    addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
        this.events.push(`${type}: ${event.value}`);
    }
}
