<div class="child" [class.child--blured]="menu">
    <router-outlet></router-outlet>
</div>

<div class="menu">
    <a routerLinkActive="is-active" class="menu__link" [routerLink]="['/home/sell']">SELL</a>
    <a routerLinkActive="is-active" class="menu__link" [routerLink]="['/home/reports']">REPORTS</a>
    <button (click)="menu = true" class="menu__btn">
        <svg class="menu__burger" width="28" height="17">
            <use xlink:href="../../../../assets/images/sprite.svg#burger"></use>
        </svg>
    </button>
</div>
<div class="addition-menu" [class.is-active]="menu">
    <ul class="addition-menu__list">
        <li class="addition-menu__item">Terms and Conditions</li>
        <li (click)="logout()" class="addition-menu__item">Logout</li>
    </ul>
    <button (click)="menu = false" class="addition-menu__btn">Cancel</button>
</div>
