import {Component, OnInit, HostListener, ElementRef, ViewChild,} from '@angular/core';
import {routerTransition, slideToLeft} from "../../../animations/router.animations";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ExistingUserService} from "../../../service/sell/existingUser.service";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {UserInfo} from "../../../models/userInfo";
import {PAYMENT_TYPES} from "../../../config/payment_types";
import {PopupComponent} from "../../../shared/popup/popup.component";
import {formatNumber,formatCurrency} from "@angular/common";
import {LoaderService} from "../../../service/loader.service";
import {PhoneMaskService} from "../../../service/phoneMask.service";

@Component({
    selector: 'app-existing-user',
    templateUrl: './existing-user-info.component.html',
    styleUrls: ['./existing-user-info.component.scss'],
    animations: [slideToLeft],
    host: {'[@slideToLeft]': '',
          '[class.is-menu]': 'menu',}
})
export class ExistingUserInfoComponent implements OnInit {
    sellForm: FormGroup;
    userInfo;
    PAYMENT_TYPES = PAYMENT_TYPES;
    menu = false;
    userId;
    phoneMask = this.phoneMaskService.setPhoneMask();

    // PAGINATION
    debitData = {
        name: 'debit',
        prevPagination: 0,
        previewPagination: 1,
        lastScrollTop: 0,
        dataRichEnd: false,
        items: [],
        limit: 0,
        loading: false,
        countLastAddedItems: 0,
        canScroll: true,
        selector: '.js-debit-item',
        selectorParent: '.js-debit-parent',
        countTotalItems: 0,
        lastAddedItem: 0
    }

    creditData = {
        name: 'credit',
        prevPagination: 0,
        previewPagination: 1,
        lastScrollTop: 0,
        dataRichEnd: false,
        items: [],
        limit: 0,
        loading: false,
        countLastAddedItems: 0,
        canScroll: true,
        selector: '.js-credit-item',
        selectorParent: '.js-credit-parent',
        countTotalItems: 0,
        lastAddedItem: 0
    }

    @ViewChild('userTel', {static: false}) userTel: ElementRef;

    constructor(private formBuilder: FormBuilder,
                private route: ActivatedRoute,
                private eRef: ElementRef,
                private loaderService: LoaderService,
                private popupComponent: PopupComponent,
                private phoneMaskService: PhoneMaskService,
                private router: Router,
                private existingUserService: ExistingUserService) {

    }


    ngOnInit() {
        this.sellForm = this.formBuilder.group({
            amount: [null, Validators.required],
        });

        this.route.params.subscribe((params: Params) => {
            this.userId = params.id;

            this.getUserInfo();
        });
    }

    onSubmit() {
        const amount = this.sellForm.controls.amount.value;
        const phone = this.userTel.nativeElement.innerHTML;

        this.popupComponent.showPopupAgreement({text: `${phone} <br><br> ${formatCurrency(amount, 'en','$')}`}, (status) => {
            if (status) {
                this.submit();
            }
        });
    }

    submit() {
        const userId = this.route.snapshot.params.id;
        const amount = this.sellForm.controls.amount.value;

        this.loaderService.show();
        this.existingUserService.sell(userId, amount).subscribe(data => {
            this.loaderService.hide();
            if(data.status){
                this.popupComponent.showPopupSuccess({text:data.message});
                this.sellForm.reset();

                this.getUserInfo();
            } else {
                this.popupComponent.showPopupError({text:data.message});
            }
        });
    }

    getUserInfo(){
        this.loaderService.show();
        this.existingUserService.getUserInfo(this.userId).subscribe(data => {
            this.loaderService.hide();
            try {
                if (data.status) {
                    this.userInfo = data;

                    this.debitData.items = data.debit;
                    this.debitData.limit = data.debitLimit ? data.debitLimit : 40;
                    this.debitData.countTotalItems = data.debitCount;
                    this.debitData.lastAddedItem = this.debitData.items.length;
                    this.debitData.dataRichEnd = +this.debitData.countTotalItems === +this.debitData.lastAddedItem;
                    this.debitData.canScroll = this.debitData.items.length >= this.debitData.limit && !this.debitData.dataRichEnd;


                    this.creditData.items = data.credit;
                    this.creditData.limit = data.creditLimit ? data.creditLimit : 40;
                    this.creditData.countTotalItems = data.creditCount;
                    this.creditData.lastAddedItem = this.creditData.items.length;
                    this.creditData.dataRichEnd = +this.creditData.countTotalItems === +this.creditData.lastAddedItem;
                    this.creditData.canScroll = this.creditData.items.length >= this.creditData.limit && !this.creditData.dataRichEnd;

                }
            } catch (e) {
                this.popupComponent.showPopupError({text: 'Sorry there was an error try again later.'});
            }

        });

    }

    getPhone(item){
        const activityItem = new UserInfo(item);
        return activityItem.phone;
    }

    getPin(item){
        const activityItem = new UserInfo(item);
        return activityItem.pin;
    }

    getId(item){
        const activityItem = new UserInfo(item);
        return activityItem.id;
    }

    getIssuedFunds(item){
        const activityItem = new UserInfo(item);
        return activityItem.totalDebit;
    }

    getRedemtions(item){
        const activityItem = new UserInfo(item);
        return activityItem.totalCredit;
    }

    getIssuedFundsItems(item){
        const activityItem = new UserInfo(item);
        return activityItem.debit;
    }

    getRedemtionsItems(item){
        const activityItem = new UserInfo(item);
        return activityItem.credit;
    }

    getPayment(id){
        const type = Object.keys(this.PAYMENT_TYPES).find(key => +this.PAYMENT_TYPES[key] === +id);
        switch(type) {
            case '0':
                return 'shop';
                break;

            case '1':
                return 'card';
                break;

            case '2':
                return 'payPal';
                break;

            default:
                return 'shop';
                break;
        }
    }

    toggleDropdown(e) {
        //console.log(e.target.offsetParent.classList);
        if (e.target.offsetParent.classList.contains('is-hidden')) {
            e.target.offsetParent.classList.remove('is-hidden');
        } else {
            e.target.offsetParent.classList.add('is-hidden');
        }
    }

    askFreezeUser() {
        this.popupComponent.showPopupAgreement({text: `Do you really want to freeze the user?`}, (status) => {
            if (status) {
                this.freezeUser();
            }
        });
    }

    freezeUser() {
        this.existingUserService.freeze(this.userId).subscribe(data => {
            if(data.status){
                this.popupComponent.showPopupSuccess({text:data.message});
            } else {
                this.popupComponent.showPopupError({text:data.message});
            }
        });
    }

    askDeleteUser() {
        this.popupComponent.showPopupAgreement({text: `Do you really want to delete the user?`,
            confirmButtonText: 'CONFIRM<svg><rect></rect></svg>',
            cancelButtonText: '<svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
                '<path d="M25.5 24.1719L18.3281 17L25.5 9.82812C25.8668 9.46136 25.8668 8.86676 25.5 8.5C25.1332 8.13324 24.5386 8.13324 24.1719 8.5L17 15.6719L9.82813 8.5C9.46136 8.13324 8.86676 8.13324 8.5 8.5C8.13324 8.86676 8.13324 9.46136 8.5 9.82812L15.6719 17L8.5 24.1719C8.13324 24.5386 8.13324 25.1332 8.5 25.5C8.86676 25.8668 9.46136 25.8668 9.82813 25.5L17 18.3281L24.1719 25.5C24.5386 25.8668 25.1332 25.8668 25.5 25.5C25.8668 25.1332 25.8668 24.5386 25.5 24.1719Z" fill="#FDFD88"/>\n' +
                '</svg>'}, (status) => {
            if (status) {
                this.deleteUser();
            }
        });
    }

    deleteUser() {
        this.existingUserService.delete(this.userId).subscribe(data => {
            if(data.status){
                this.popupComponent.showPopupSuccess({text:data.message});

                this.router.navigate(['/sell/existing-user']);
            } else {
                this.popupComponent.showPopupError({text:data.message});
            }
        });
    }

    getDate(date) {
        return date.replace(/\s/g, 'T');
    }


    ///////---SCROLLING PAGINATION FUNCTIONAL---///////

    // DETECTING SCROLL
    scroll(e, settings) {
        if (!settings.loading && settings.canScroll) {
            const elems = document.querySelectorAll(settings.selector);

            const rect = elems[elems.length - 1] !== undefined ? elems[elems.length - 1].getBoundingClientRect() : undefined;
            let rectStart;
            let documentRect = document.querySelector(settings.selectorParent).getBoundingClientRect();

            if (settings.prevPagination > 0) {
                rectStart = elems[0] !== undefined ? elems[Math.round(settings.limit*.8)].getBoundingClientRect() : undefined;
            } else {
                rectStart = undefined;
            }

            var st = e.target.pageYOffset || e.target.scrollTop;
            if (st > settings.lastScrollTop){
                if (rect !== undefined && rect.bottom < documentRect.bottom + rect.height * Math.round(settings.limit*.2) && !settings.dataRichEnd) {
                    settings.loading = true;
                    const loadingPage = settings.previewPagination + 1;
                    this.loadNewData(loadingPage, 'end', settings);

                }

            } else {
                if (e.target.scrollTop <= rect.height * 15  && settings.prevPagination > 0) {
                    settings.loading = true;
                    const loadingPage = (settings.countLastAddedItems < settings.limit &&  settings.countLastAddedItems !== 0) ? settings.prevPagination - 1 : settings.prevPagination;
                    this.loadNewData(loadingPage, 'start', settings);
                }
            }
            settings.lastScrollTop = st <= 0 ? 0 : st;

        }
    }

    // REQUESTS ON ADDING NEW DATA
    loadNewData(page, step = 'end', settings):any {
        if (settings.name ===  'debit') {
            this.existingUserService.getUserDebit(this.userId, page).subscribe(data => {
                this.dataAddingLogic(page, step, data, settings);
            });
        } else if (settings.name ===  'credit') {
            this.existingUserService.getUserCredit(this.userId, page).subscribe(data => {
                this.dataAddingLogic(page, step, data, settings);
            });
        }
    }

    // ADDING NEW DATA
    dataAddingLogic(page, step, data, settings) {

        if (data.status) {

            // ADD PAGE TO THE END
            if (step === 'end') {

                // UPDATE SETTINGS
                settings.lastAddedItem += data.result.length;
                settings.previewPagination += 1;
                if (+settings.lastAddedItem === +settings.countTotalItems) {
                    settings.dataRichEnd = true;
                }

                // DATA RICH END LOGIC
                if (settings.dataRichEnd) {
                    settings.countLastAddedItems = data.result.length;
                    if (settings.items.length > (settings.limit * 2)) {
                        settings.prevPagination += 1;
                    }

                } else {
                    // REMOVE PAGE FROM BEGINING
                    if (settings.items.length > (settings.limit * 2)) {
                        settings.items.splice(0, data.result.length);
                        settings.prevPagination += 1;
                    }
                }

                // ADD PAGE TO THE END
                for (let i = 0; i < data.result.length; i++) {
                    settings.items.push(data.result[i]);
                };
            }

            // ADD PAGE TO BEGIN
            if ((step === 'start') && settings.prevPagination) {

                let additionalRemovedItems = 0;

                if (settings.countLastAddedItems < settings.limit && settings.countLastAddedItems !== 0) {
                    additionalRemovedItems = settings.countLastAddedItems;
                    settings.prevPagination -= 2;
                    settings.previewPagination -= 2;
                }
                else {
                    settings.prevPagination -= 1;
                    settings.previewPagination -= 1;
                }

                // REMOVE PAGE FROM END
                settings.items.splice(settings.items.length - data.result.length - additionalRemovedItems, data.result.length + additionalRemovedItems);
                settings.lastAddedItem -= data.result.length + additionalRemovedItems;

                // ADD PAGE TO BEGINING
                for (let i = data.result.length-1; i >= 0; i--) {
                    settings.items.unshift(data.result[i]);
                }
                settings.dataRichEnd = false;

            }

            settings.loading = false;
        }
    }

    getPhoneMask(item){
        if(item.country_id){
            return this.phoneMaskService.getPhoneMaskByCountryId(item.country_id);
        }else{
            return this.phoneMaskService.getDefaultPhoneMask();
        }

    }
}
