import {Component, OnInit} from '@angular/core';
import {routerTransition, slideToLeft} from "../../../animations/router.animations";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ExistingUserService} from "../../../service/sell/existingUser.service";
import {ActivityItem} from "../../../models/activityItem";
import {ActivatedRoute, Router} from "@angular/router";
import {LoaderService} from "../../../service/loader.service";
import {PhoneMaskService} from "../../../service/phoneMask.service";

@Component({
    selector: 'app-existing-user',
    templateUrl: './existing-user.component.html',
    styleUrls: ['./existing-user.component.scss'],
    animations: [slideToLeft],
    host: {'[@slideToLeft]': ''}
})
export class ExistingUserComponent implements OnInit {
    existingUserForm: FormGroup;
    phoneMask = this.phoneMaskService.setPhoneMask();

    constructor(private formBuilder: FormBuilder,
                private route: ActivatedRoute,
                private loaderService: LoaderService,
                private router: Router,
                private phoneMaskService: PhoneMaskService,
                private existingUserService: ExistingUserService) {

    }

    users;

    // PAGINATION NEW
    usersData = {
        name: 'users',
        prevPagination: 0,
        previewPagination: 1,
        lastScrollTop: 0,
        dataRichEnd: false,
        items: [],
        limit: 0,
        loading: false,
        countLastAddedItems: 0,
        canScroll: true,
        selector: '.js-activity-item',
        selectorParent: '.page__body',
        countTotalItems: 0,
        lastAddedItem: 0
    }

    ngOnInit() {
        this.existingUserForm = this.formBuilder.group({
            phone: [null, Validators.required],
        });


        this.getUsers('');
    }

    onSubmit() {
        // if (this.existingUserForm.invalid) {
        //     return;
        // }

        this.getUsers(this.inputHasValue());
    }

    getUsers(phone){
        this.loaderService.show();
        this.existingUserService.getUsers(phone).subscribe(data => {
            this.loaderService.hide();

            this.resetPagination(this.usersData);
            try {
                if (data.status) {

                    this.usersData.items = data.result;

                    this.usersData.limit = data.limit ? data.limit : 40;
                    this.usersData.countTotalItems = data.total;
                    this.usersData.lastAddedItem = this.usersData.items.length;
                    this.usersData.dataRichEnd = +this.usersData.countTotalItems === +this.usersData.lastAddedItem;
                    this.usersData.canScroll = this.usersData.items.length >= this.usersData.limit && !this.usersData.dataRichEnd;

                }
            }catch (e) {

            }

        });
    }

    inputHasValue() {
        return this.existingUserForm.controls.phone.value;
    }

    resetInput() {
        this.existingUserForm.controls.phone.setValue(null);
    }

    openUserSettings(id) {
        this.router.navigate(['existing-user-info', id], {relativeTo: this.route});
    }

    ///////---SCROLLING PAGINATION FUNCTIONAL---///////

    resetPagination(data) {
        data.prevPagination = 0;
        data.previewPagination = 1;
        data.lastScrollTop = 0;
        data.items= [];
        data.loading = false;
        data.countLastAddedItems = 0;
        data.lastAddedItem = 0;
    }

    // DETECTING SCROLL
    scroll(e, settings) {
        if (!settings.loading && settings.canScroll) {
            const elems = document.querySelectorAll(settings.selector);

            const rect = elems[elems.length - 1] !== undefined ? elems[elems.length - 1].getBoundingClientRect() : undefined;
            let rectStart;
            let documentRect = document.querySelector(settings.selectorParent).getBoundingClientRect();

            if (settings.prevPagination > 0) {
                rectStart = elems[0] !== undefined ? elems[Math.round(settings.limit*.8)].getBoundingClientRect() : undefined;
            } else {
                rectStart = undefined;
            }

            var st = e.target.pageYOffset || e.target.scrollTop;
            if (st > settings.lastScrollTop){
                if (rect !== undefined && rect.bottom < documentRect.bottom + rect.height * Math.round(settings.limit*.2) && !settings.dataRichEnd) {
                    settings.loading = true;
                    const loadingPage = settings.previewPagination + 1;
                    this.loadNewData(loadingPage, 'end', settings);

                }

            } else {
                if (e.target.scrollTop <= rect.height * 15  && settings.prevPagination > 0) {
                    settings.loading = true;
                    const loadingPage = (settings.countLastAddedItems < settings.limit &&  settings.countLastAddedItems !== 0) ? settings.prevPagination - 1 : settings.prevPagination;
                    this.loadNewData(loadingPage, 'start', settings);
                }
            }
            settings.lastScrollTop = st <= 0 ? 0 : st;

        }
    }

    // REQUESTS ON ADDING NEW DATA
    loadNewData(page, step = 'end', settings):any {
        const phone = this.existingUserForm.controls.phone.value ? this.existingUserForm.controls.phone.value : '';
        this.existingUserService.getUsers(phone).subscribe(data => {
            this.dataAddingLogic(page, step, data, settings);
        });
    }

    // ADDING NEW DATA
    dataAddingLogic(page, step, data, settings) {

        if (data.status) {

            // ADD PAGE TO THE END
            if (step === 'end') {

                // UPDATE SETTINGS
                settings.lastAddedItem += data.result.length;
                settings.previewPagination += 1;
                if (+settings.lastAddedItem === +settings.countTotalItems) {
                    settings.dataRichEnd = true;
                }

                // DATA RICH END LOGIC
                if (settings.dataRichEnd) {
                    settings.countLastAddedItems = data.result.length;
                    if (settings.items.length > (settings.limit * 2)) {
                        settings.prevPagination += 1;
                    }

                } else {
                    // REMOVE PAGE FROM BEGINING
                    if (settings.items.length > (settings.limit * 2)) {
                        settings.items.splice(0, data.result.length);
                        settings.prevPagination += 1;
                    }
                }

                // ADD PAGE TO THE END
                for (let i = 0; i < data.result.length; i++) {
                    settings.items.push(data.result[i]);
                };
            }

            // ADD PAGE TO BEGIN
            if ((step === 'start') && settings.prevPagination) {

                let additionalRemovedItems = 0;

                if (settings.countLastAddedItems < settings.limit && settings.countLastAddedItems !== 0) {
                    additionalRemovedItems = settings.countLastAddedItems;
                    settings.prevPagination -= 2;
                    settings.previewPagination -= 2;
                }
                else {
                    settings.prevPagination -= 1;
                    settings.previewPagination -= 1;
                }

                // REMOVE PAGE FROM END
                settings.items.splice(settings.items.length - data.result.length - additionalRemovedItems, data.result.length + additionalRemovedItems);
                settings.lastAddedItem -= data.result.length + additionalRemovedItems;

                // ADD PAGE TO BEGINING
                for (let i = data.result.length-1; i >= 0; i--) {
                    settings.items.unshift(data.result[i]);
                }
                settings.dataRichEnd = false;

            }

            settings.loading = false;
        }
    }


}
