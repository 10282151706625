import { OnInit } from '@angular/core';
import Swal from "sweetalert2";
import * as i0 from "@angular/core";
var PopupComponent = /** @class */ (function () {
    function PopupComponent() {
        this.swalStyled = Swal.mixin({
            customClass: {
                container: 'popup',
                popup: 'popup__inner',
                image: 'popup__image',
                title: 'popup__title',
                content: 'popup__content',
                confirmButton: 'popup__btn popup__btn_confirm snake-btn is-notouched',
                cancelButton: 'popup__btn popup__btn_cancel'
            },
        });
    }
    PopupComponent.prototype.ngOnInit = function () {
    };
    PopupComponent.prototype.showPopupSuccess = function (message) {
        this.swalStyled.fire({
            showClass: {
                popup: 'swal-success'
            },
            text: message.text ? message.text : 'The action was successful!',
            confirmButtonText: 'OK, THANKS',
        });
    };
    PopupComponent.prototype.showPopupError = function (message) {
        var text = message.text ? message.text : 'Oops! Something is not right.';
        var confirmButtonText = message.confirmButtonText ? message.confirmButtonText : 'BACK';
        var closeBtn = message.closeBtn === false ? message.closeBtn : true;
        this.swalStyled.fire({
            // title: 'Error',
            // imageUrl: '../assets/images/popup/error-logo.png',
            //className: "swal-back",
            showClass: {
                popup: 'swal-error'
            },
            text: text,
            showConfirmButton: closeBtn,
            allowOutsideClick: closeBtn,
            confirmButtonText: confirmButtonText
        });
    };
    PopupComponent.prototype.showPopupAgreement = function (options, handler) {
        var text = options.text ? options.text : 'Something went wrong. Please try again.';
        var confirmButtonText = options.confirmButtonText ? options.confirmButtonText : 'CONFIRM<svg><rect></rect></svg>';
        var cancelButtonText = options.cancelButtonText ? options.cancelButtonText : '<svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
            '<path d="M25.5 24.1719L18.3281 17L25.5 9.82812C25.8668 9.46136 25.8668 8.86676 25.5 8.5C25.1332 8.13324 24.5386 8.13324 24.1719 8.5L17 15.6719L9.82813 8.5C9.46136 8.13324 8.86676 8.13324 8.5 8.5C8.13324 8.86676 8.13324 9.46136 8.5 9.82812L15.6719 17L8.5 24.1719C8.13324 24.5386 8.13324 25.1332 8.5 25.5C8.86676 25.8668 9.46136 25.8668 9.82813 25.5L17 18.3281L24.1719 25.5C24.5386 25.8668 25.1332 25.8668 25.5 25.5C25.8668 25.1332 25.8668 24.5386 25.5 24.1719Z" fill="#FDFD88"/>\n' +
            '</svg>';
        this.swalStyled.fire({
            showClass: {
                popup: 'swal-agreement',
            },
            html: text,
            confirmButtonText: confirmButtonText,
            cancelButtonText: cancelButtonText,
            showCancelButton: true,
        }).then(function (result) {
            if (result.isConfirmed) {
                return handler(true);
            }
            else {
                return handler(false);
            }
        });
    };
    PopupComponent.prototype.closePopup = function () {
        this.swalStyled.close();
    };
    PopupComponent.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PopupComponent_Factory() { return new PopupComponent(); }, token: PopupComponent, providedIn: "root" });
    return PopupComponent;
}());
export { PopupComponent };
