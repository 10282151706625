import { EXISTING_USER_CONFIG } from "../../config/api";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ExistingUserService = /** @class */ (function () {
    function ExistingUserService(http) {
        this.http = http;
        this.config = EXISTING_USER_CONFIG;
    }
    ExistingUserService.prototype.getUsers = function (phone) {
        return this.http.post(this.config.getUsers, { phone: phone });
    };
    ExistingUserService.prototype.getUserInfo = function (id) {
        return this.http.post(this.config.userInfo, { id: id });
    };
    ExistingUserService.prototype.getUserCredit = function (id, page) {
        return this.http.post(this.config.userCredit, { id: id, page: page });
    };
    ExistingUserService.prototype.getUserDebit = function (id, page) {
        return this.http.post(this.config.userDebit, { id: id, page: page });
    };
    ExistingUserService.prototype.freeze = function (id) {
        return this.http.post(this.config.freeze, { id: id });
    };
    ExistingUserService.prototype.delete = function (id) {
        return this.http.post(this.config.delete, { id: id });
    };
    ExistingUserService.prototype.sell = function (id, amount) {
        return this.http.post(this.config.sell, { id: id, amount: amount });
    };
    ExistingUserService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ExistingUserService_Factory() { return new ExistingUserService(i0.ɵɵinject(i1.HttpClient)); }, token: ExistingUserService, providedIn: "root" });
    return ExistingUserService;
}());
export { ExistingUserService };
